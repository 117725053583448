body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;

}

.star {
  position: relative;
}

.star:hover::before,
.star.selected::before
		 {
		   content: "\2605";
		   position: absolute;
		   left: 0;
		   color: gold;
		}