@import "~antd/dist/antd.css";

html,
body,
.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow-y: scroll;
  background-color: #f9fbff !important;
  font-family: "Roboto", sans-serif !important;
}

.App-logo {
  height: 80px;
}

.App-title {
  font-size: 1.4em;
}

.ant-tooltip-inner {
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.56);
  padding: 10px 8px;
}

h6 {
  margin-top: -20px;
}
